import axiosIns from "@/libs/axios";

const get = async () => {
  return await axiosIns.get(`funciones`)
}

const store = async (data) => {
  return await axiosIns.post('funciones', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`funciones/${id}`, data)
}

const destroy = async (id) => {
  return await axiosIns.delete(`funciones/${id}`)
}

const getPermisos = async () => {
  return await axiosIns.get(`permisos`)
}

const getGrupos = async () => {
  return await axiosIns.get(`permisos/grupos`)
}


export default {
  get,
  store,
  update,
  destroy,
  getPermisos,
  getGrupos,
}
